import React from "react";
import { graphql, navigate } from "gatsby";

import Helmet from "react-helmet";

import siteConfig from "../../data/SiteConfig";

import Page from "../components/Layout/Page";
import Post from "../components/Post";

import { FixedWidthContainer } from "../components/Layout/ContentContainers";
import Button from "../components/Forms/Button";

class NewsPostTemplate extends React.Component {
  getMetaUrl() {
    let baseUrl = `${siteConfig.siteUrl}/news`;

    if (this.props.pathContext.slug) {
      baseUrl += `/${this.props.pathContext.slug}`;
    }
    return baseUrl;
  }

  getMetaTitle() {
    let pageTitle = siteConfig.siteTitle;

    if (this.props.data.wordpressPost.title) {
      pageTitle = `${this.props.data.wordpressPost.title} | ${pageTitle}`;
    }
    return pageTitle;
  }

  render() {
    const { data, pathContext } = this.props;
    const { slug } = pathContext;
    const postNode = data.wordpressPost;
    if (!postNode.id) {
      postNode.id = slug;
    }

    const categoryList = postNode.categories.map(category => category.name);
    let backButton;
    if (categoryList.includes("FEST 22")) {
      backButton = (
        <Button
          onClick={() => navigate(`/news`)}
          style={{ marginBottom: "18px" }}
        >
          ← Back to News
        </Button>
      );
    } else if (categoryList.includes("Blog")) {
      backButton = (
        <Button
          onClick={() => navigate(`/blog`)}
          style={{ marginBottom: "18px" }}
        >
          ← Back to Blog
        </Button>
      );
    }

    return (
      <Page>
        <Helmet>
          <meta property="og:url" content={this.getMetaUrl()} />
          <meta property="og:title" content={this.getMetaTitle()} />
          <meta property="og:type" content="article" />
        </Helmet>
        <FixedWidthContainer>
          {backButton}
          <Post post={postNode} />
        </FixedWidthContainer>
      </Page>
    );
  }
}

export default NewsPostTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query PostById($id: String!) {
    wordpressPost(id: { eq: $id }) {
      date
      slug
      title
      modified
      excerpt
      id
      content
      categories {
        name
      }
      acf {
        external_published_date
      }
    }
  }
`;
